// Nivel de impacto
export const nivelImpacto = [
  { value: '', label: '- Selecciona impacto -' },
  { value: 'Muy Bajo', label: 'Muy Bajo' },
  { value: 'Bajo', label: 'Bajo' },
  { value: 'Medio', label: 'Medio' },
  { value: 'Alto', label: 'Alto' },
  { value: 'Muy Alto', label: 'Muy Alto' },
];

export const YesOrNo = [
  {
    label: 'Sí',
    value: 1,
  },
  {
    label: 'No',
    value: 0,
  },
];

export const formOptions = [
  {
    label: 'Básico',
    value: 1,
  },
  {
    label: 'Específico',
    value: 0,
  },
];

export const wordFilterOptions = [
  {
    label: 'Todos los indicadores',
    value: 1,
  },
  {
    label: 'Por rango de fechas',
    value: 2,
  },
  {
    label: 'Indicadores ESRS',
    value: 3,
  },
  {
    label: 'Indicadores GRI',
    value: 4,
  },
];

export const indicatorCertificate = [
  {
    label: 'ESRS',
    value: 1,
    image: 'assets/images/esrs.png',
  },
  {
    label: 'GRI',
    value: 2,
    image: 'assets/images/gri.png',
  },
];

export const numberScale = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
];

// Nivel de impacto financiero
export const nivelImpactoFinanciero = [
  { value: '', label: '- Selecciona impacto financiero -' },
  { value: 'Muy Bajo', label: 'Muy Bajo' },
  { value: 'Bajo', label: 'Bajo' },
  { value: 'Medio', label: 'Medio' },
  { value: 'Alto', label: 'Alto' },
  { value: 'Muy Alto', label: 'Muy Alto' },
];

// Filtrado de indicadores por estado, aplicabilidad y tipo
export const indicatorState = [
  { value: 'NO_APPLIES_STATE', label: 'No aplica' },
  { value: 'VALIDATED', label: 'Validado' },
  { value: 'NO_VALIDATED', label: 'No validado' },
];

export const indicatorGap = [
  { value: 'APPLIES_GAP', label: 'Aplica' },
  { value: 'APPLIES_NO_TREATED', label: 'Aplica y no trata' },
  { value: 'NO_APPLIES_GAP', label: 'No aplica' },
  { value: 'VOLUNTEER', label: 'Voluntario' },
  { value: 'NO_ANALYZED', label: 'No analizado' },
];

export const indicatorType = [
  { value: 'qualitative', label: 'Cualitativo' },
  { value: 'quantitative', label: 'Cuantitativo' },
  { value: 'noType', label: 'Sin tipificar' },
  { value: 'gap', label: 'Sin tipificar con aplicabilidad' },
];
// Fin filtrado

// Array de objetos para "responsableid"
export const responsables = [
  { value: '1112', label: 'JUAN PEREZ' },
  { value: '1113', label: 'MARIA RICAURTE' },
  { value: '1114', label: 'RENE RIVAS' },
  { value: '1115', label: 'RICARDO LEON' },
  { value: '1116', label: 'PEDRO CIFUENTES' },
  { value: '1117', label: 'JUAN ARBOLEDA' },
  { value: '1118', label: 'LUISA HERRERA' },
  { value: '1119', label: 'PATRICIO SOSA' },
  { value: '1120', label: 'EDUARDO MALO' },
  { value: '1121', label: 'ROCIO LARREA' },
  { value: '1122', label: 'CATALINA GUERRA' },
  { value: '1123', label: 'FERNANDO ORTIZ' },
  { value: '1124', label: 'MARIO GUERRON' },
  { value: '1125', label: 'ANA LLERENA' },
  { value: '1126', label: 'PABLO PITAR' },
  { value: '1127', label: 'DOLORES RIBADENEIRA' },
  { value: '1128', label: 'PETER VILLEGAS' },
  { value: '1129', label: 'JUAN CARLOS SALAS' },
  { value: '1130', label: 'MARIA SOL GALARZA' },
  { value: '1131', label: 'Vicente perez' },
  { value: '1132', label: 'jose garcia' },
  { value: '1133', label: 'luis lopez' },
  { value: '1134', label: 'vicente rodriguez' },
  { value: '1135', label: 'alberto fontes' },
  { value: '1136', label: 'edgar klab' },
  { value: '1137', label: 'teresa rodriguez' },
  { value: '1138', label: 'JUAN MANUEL' },
  { value: '1139', label: 'SANTIAGO' },
  { value: '1140', label: 'EDUARDO' },
  { value: '1141', label: 'VICTOR' },
  { value: '1142', label: 'MARIO' },
  { value: '1143', label: 'ROBERTO' },
  { value: '1144', label: 'JAIME' },
  { value: '1145', label: 'FRANCISCO JOSE' },
  { value: '1146', label: 'IGNACIO' },
  { value: '1147', label: 'MARCOS' },
  { value: '1148', label: 'ALFONSO' },
  { value: '1149', label: 'JORDI' },
  { value: '1150', label: 'SALVADOR' },
  { value: '1151', label: 'RICARDO' },
  { value: '1152', label: 'EMILIO' },
  { value: '1153', label: 'HUGO' },
  { value: '1154', label: 'GUILLERMO' },
  { value: '1155', label: 'GABRIEL' },
  { value: '1156', label: 'JULIAN' },
  { value: '1157', label: 'JULIO' },
  { value: '1158', label: 'MARC' },
  { value: '1159', label: 'TOMAS' },
  { value: '1160', label: 'JOSE MIGUEL' },
  { value: '1161', label: 'GONZALO' },
  { value: '1162', label: 'AGUSTIN' },
  { value: '1163', label: 'MOHAMED' },
  { value: '1164', label: 'JOSE RAMON' },
  { value: '1165', label: 'FELIX' },
  { value: '1166', label: 'NICOLAS' },
  { value: '1167', label: 'JOAN' },
  { value: '1168', label: 'MARTIN' },
  { value: '1169', label: 'ISMAEL' },
  { value: '1170', label: 'CRISTIAN' },
  { value: '1171', label: 'SAMUEL' },
  { value: '1172', label: 'AITOR' },
  { value: '1173', label: 'JUAN FRANCISCO' },
  { value: '1174', label: 'JOSEP' },
  { value: '1175', label: 'HECTOR' },
  { value: '1176', label: 'MARIANO' },
  { value: '1177', label: 'DOMINGO' },
  { value: '1178', label: 'JOSE CARLOS' },
  { value: '1179', label: 'ALFREDO' },
  { value: '1180', label: 'SEBASTIAN' },
  { value: '1181', label: 'IKER' },
  { value: '1182', label: 'CESAR' },
  { value: '1183', label: 'FELIPE' },
  { value: '1184', label: 'ALEX' },
  { value: '1185', label: 'LUCAS' },
  { value: '1186', label: 'JOSE ANGEL' },
  { value: '1187', label: 'JOSE IGNACIO' },
  { value: '1188', label: 'VICTOR MANUEL' },
  { value: 1189, label: 'LUIS MIGUEL' },
  { value: 1190, label: 'RODRIGO' },
  { value: 1191, label: 'GREGORIO' },
  { value: 1192, label: 'JOSE FRANCISCO' },
  { value: 1193, label: 'JUAN LUIS' },
  { value: 1194, label: 'XAVIER' },
  { value: 1195, label: 'ALBERT' },
  { value: 1196, label: 'MARIA' },
  { value: 1197, label: 'CARMEN' },
  { value: 1198, label: 'ANA' },
  { value: 1199, label: 'ISABEL' },
  { value: 1200, label: 'DOLORES' },
  { value: 1201, label: 'PILAR' },
  { value: 1202, label: 'TERESA' },
  { value: 1203, label: 'JOSEFA' },
  { value: 1204, label: 'ROSA' },
  { value: 1205, label: 'CRISTINA' },
  { value: 1206, label: 'ANGELES' },
  { value: 1207, label: 'ANTONIA' },
  { value: 1208, label: 'LAURA' },
  { value: 1209, label: 'ELENA' },
  { value: 1210, label: 'FRANCISCA' },
  { value: 1211, label: 'MARTA' },
  { value: 1212, label: 'MERCEDES' },
  { value: 1213, label: 'LUCIA' },
  { value: 1214, label: 'LUISA' },
  { value: 1215, label: 'CONCEPCION' },
  { value: 1216, label: 'ROSARIO' },
  { value: 1217, label: 'JOSE' },
  { value: 1218, label: 'PAULA' },
  { value: 1219, label: 'SARA' },
  { value: 1220, label: 'JUANA' },
  { value: 1221, label: 'RAQUEL' },
  { value: 1222, label: 'MANUELA' },
  { value: 1223, label: 'EVA' },
  { value: 1224, label: 'JESUS' },
  { value: 1225, label: 'BEATRIZ' },
  { value: 1226, label: 'ROCIO' },
  { value: 1227, label: 'PATRICIA' },
  { value: 1228, label: 'VICTORIA' },
  { value: 1229, label: 'JULIA' },
  { value: 1230, label: 'BELEN' },
  { value: 1231, label: 'ENCARNACION' },
  { value: 1232, label: 'ANDREA' },
  { value: 1233, label: 'SILVIA' },
  { value: 1234, label: 'ESTHER' },
  { value: 1235, label: 'NURIA' },
  { value: 1236, label: 'ALBA' },
  { value: 1237, label: 'MONTSERRAT' },
  { value: 1238, label: 'IRENE' },
  { value: 1239, label: 'ANGELA' },
  { value: 1240, label: 'INMACULADA' },
  { value: 1241, label: 'MONICA' },
  { value: 1242, label: 'SANDRA' },
  { value: 1243, label: 'YOLANDA' },
  { value: 1244, label: 'ALICIA' },
  { value: 1245, label: 'SONIA' },
  { value: 1246, label: 'MAR' },
  { value: 1247, label: 'MARGARITA' },
  { value: 1248, label: 'MARINA' },
  { value: 1249, label: 'SUSANA' },
  { value: 1250, label: 'NATALIA' },
  { value: 1251, label: 'CLAUDIA' },
  { value: 1252, label: 'AMPARO' },
  { value: 1253, label: 'NIEVES' },
  { value: 1254, label: 'GLORIA' },
  { value: 1255, label: 'INES' },
  { value: 1256, label: 'CAROLINA' },
  { value: 1257, label: 'SOFIA' },
  { value: 1258, label: 'VERONICA' },
  { value: 1259, label: 'LOURDES' },
  { value: 1260, label: 'SOLEDAD' },
  { value: 1261, label: 'NOELIA' },
  { value: 1262, label: 'LUZ' },
  { value: 1263, label: 'CARLA' },
  { value: 1264, label: 'BEGOÑA' },
  { value: 1265, label: 'LORENA' },
  { value: 1266, label: 'ALEJANDRA' },
  { value: 1267, label: 'CONSUELO' },
  { value: 1268, label: 'ASUNCION' },
  { value: 1269, label: 'DANIELA' },
  { value: 1270, label: 'OLGA' },
  { value: 1271, label: 'FATIMA' },
  { value: 1272, label: 'MILAGROS' },
  { value: 1273, label: 'ESPERANZA' },
  { value: 1274, label: 'BLANCA' },
  { value: 1275, label: 'CATALINA' },
  { value: 1276, label: 'NEREA' },
  { value: 1277, label: 'MIRIAM' },
  { value: 1278, label: 'LIDIA' },
  { value: 1279, label: 'CLARA' },
  { value: 1280, label: 'AURORA' },
  { value: 1281, label: 'EMILIA' },
  { value: 1282, label: 'MAGDALENA' },
  { value: 1283, label: 'CELIA' },
  { value: 1284, label: 'ANNA' },
  { value: 1285, label: 'ELISA' },
  { value: 1286, label: 'EUGENIA' },
  { value: 1287, label: 'ADRIANA' },
  { value: 1288, label: 'VIRGINIA' },
  { value: 1289, label: 'VANESA' },
  { value: 1290, label: 'JOSEFINA' },
  { value: 1291, label: 'GEMA' },
  { value: 1292, label: 'AINHOA' },
  { value: 1293, label: 'PURIFICACION' },
  { value: 1294, label: 'REMEDIOS' },
  { value: 1295, label: 'MARTINA' },
  { value: 12003, label: 'David Prueba N1' },
  { value: 12064, label: 'Prueba jess 1 octubre' },
  { value: 12068, label: 'Vicente Casaña' },
  { value: 12069, label: 'Vicente Casaña' },
  { value: 12070, label: 'Jessica Garcia' },
  { value: 12071, label: 'Jessica Prueba Formacion' },
  { value: 12084, label: 'Christian' },
  { value: 12549, label: 'Empleado 1A' },
  { value: 13206, label: 'Jessica García' },
  { value: 13233, label: 'QLDB-Test' },
  { value: 13234, label: 'QLDB-Test' },
  { value: 13235, label: 'QLDB-Test' },
  { value: 13236, label: 'QLDB-Test' },
  { value: 13238, label: 'QLDB-Test' },
  { value: 13240, label: 'QLDB-Test' },
  { value: 13242, label: 'QLDB-Test' },
  { value: 13244, label: 'QLDB-Test' },
  { value: 13246, label: 'Jessica Prueba MailServerNew' },
  { value: 13316, label: 'Jose IT' },
  { value: 13352, label: 'Jose pruebas' },
  { value: 13356, label: 'Jose IT' },
  { value: 13357, label: 'Jose Import IT nombre' },
];

export const departamentos = [
  { value: 177, label: 'Dirección/Gerencia' },
  { value: 178, label: 'Recursos Humanos' },
  { value: 179, label: 'Marketing' },
  { value: 180, label: 'Control de Gestión' },
  { value: 181, label: 'Financiero' },
  { value: 182, label: 'Administración' },
  { value: 183, label: 'Compras/Aprovisionamientos' },
  { value: 184, label: 'Producción' },
  { value: 185, label: 'Comercial/Ventas' },
  { value: 186, label: 'Informática' },
  { value: 187, label: 'Auditoría interna' },
  { value: 188, label: 'Jurídico interno' },
  { value: 189, label: 'Logístico' },
  { value: 190, label: 'Fiscal interno' },
  { value: 1168, label: 'Desarrollo' },
  { value: 1201, label: 'VENTAS' },
  { value: 1202, label: 'SOPORTE TÉCNICO' },
  { value: 1203, label: 'LEGAL' },
  { value: 1204, label: 'COMPRAS' },
  { value: 1448, label: 'QLDB-Test' },
  { value: 1482, label: 'Revision staging' },
];
