import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { CompanyIdHeaderService } from './../services/company-id-header.service';

@Injectable({
  providedIn: 'root',
})
export class ServerService {
  public apiUrl = environment.esrsApiURL;
  companyId: any;

  constructor(
    private http: HttpClient,
    private companyIdService: CompanyIdHeaderService
  ) {}

  /**
   * Método para obtener datos del servidor
   * @param relativeUrl
   * @returns
   */
  getData(relativeUrl: string): Observable<any> {
    const token = sessionStorage.getItem('token');
    const companyId = 1;

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
      company_id: companyId || '',
    });

    const url = this.apiUrl + relativeUrl;
    return this.http.get(url, { headers });
  }

  /**
   * Método para obtener datos del API de usuarios
   * @param relativeUrl
   * @returns
   */
  getDataUsersApi(relativeUrl: string): Observable<any> {
    const token = sessionStorage.getItem('token');
    const companyId = 1;

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
      company_id: companyId || '',
    });

    const url = environment.usersApiURL + relativeUrl;
    return this.http.get(url, { headers });
  }

  /**
   * Método para enviar datos al API de usuarios
   * @param relativeUrl
   * @param data
   * @returns
   */
  sendDataUsersApi(relativeUrl: string, data?: any): Observable<any> {
    const token = sessionStorage.getItem('token');
    const companyId = 1;

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
      company_id: companyId || '',
    });

    const url = environment.usersApiURL + relativeUrl;

    return this.http.post<any>(url, data, { headers }).pipe(
      tap((response) => {}),
      catchError((error: HttpErrorResponse) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Método para eliminar datos al API de usuarios
   * @param relativeUrl
   * @param data
   * @returns
   */
  deleteDataUsersApi(relativeUrl: string, data?: any): Observable<any> {
    const token = sessionStorage.getItem('token');
    const companyId = 1;

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
      company_id: companyId || '',
    });

    const url = environment.usersApiURL + relativeUrl;

    return this.http.delete<any>(url, { headers }).pipe(
      tap((response) => {}),
      catchError((error: HttpErrorResponse) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Método para comprobar la disponibilidad de Azure en servidor
   * @returns
   */
  public getAzureUsersEnv() {
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
    });
    const url = environment.usersApiURL + '/api/checkMicrosoftEnv';
    return this.http.get(url, { headers });
  }

  /**
   * Método para enviar datos al servidor
   * @param relativeUrl
   * @param data
   * @returns
   */
  sendData(relativeUrl: string, data?: any): Observable<any> {
    const token = sessionStorage.getItem('token');
    const companyId = 1;

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
      company_id: companyId || '',
    });

    const url = this.apiUrl + relativeUrl;

    return this.http.post<any>(url, data, { headers }).pipe(
      tap((response) => {}),
      catchError((error: HttpErrorResponse) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Método para actualizar datos al servidor
   */
  updateData(relativeUrl: string, data: any) {
    const token = sessionStorage.getItem('token');
    const companyId = 1;

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
      company_id: companyId || '',
    });

    const url = this.apiUrl + relativeUrl;
    return this.http.put<any>(url, data, { headers }).pipe(
      tap((response) => {}),
      catchError((error: HttpErrorResponse) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Método para actualizar datos al servidor
   */
  updatePostData(relativeUrl: string, data: any) {
    const token = sessionStorage.getItem('token');
    const companyId = 1;

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
      company_id: companyId || '',
    });

    const url = this.apiUrl + relativeUrl;
    return this.http.post<any>(url, data, { headers }).pipe(
      tap((response) => {}),
      catchError((error: HttpErrorResponse) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Método para eliminar datos del servidor
   * @param relativeUrl
   * @returns
   */
  deleteData(relativeUrl: string): Observable<any> {
    const token = sessionStorage.getItem('token');
    const companyId = 1;

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
      company_id: companyId || '',
    });
    const url = this.apiUrl + relativeUrl;
    return this.http.delete(url, { headers });
  }
}
