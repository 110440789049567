<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

<ion-grid class="p-4">
  <ion-row>
    <ion-col size="12">
      <h2>Dashboard fase onboarding</h2>
    </ion-col>
  </ion-row>

  @if(loading){
  <div class="spinner-container-indicator">
    <ion-spinner name="dots"></ion-spinner>
    <ion-label>Cargando dashboard...</ion-label>
  </div>
  }

  <!-- Permisos de lectura de menú -->
  @if(!loading && !permissionService.checkSectionPermission(userByCompany, 1,
  1)){
  <div class="flex justify-content-center align-items-center">
    <h5>No tiene permiso para ver el menú lateral al completo</h5>
  </div>
  }

  <!-- Permiso a true de creación en dashboard -->
  @if(!loading && user && userByCompany && userByCompany?.sections && (user.id
  === 1 || (user.id !== 1 &&
  permissionService.checkSectionPermission(userByCompany, 16, 1)))){
  <div class="new-chart-container">
    <div class="text-container">
      <p class="">
        Pulse en <strong>Nueva gráfica</strong> para añadir una nueva gráfica a
        su dashboard. Podrá definir qué indicador mostrar, cómo presentar los
        datos (acumulados o desglosados), el campo del eje Y y las acciones en
        caso de que desee mostrar datos desglosados.
      </p>
    </div>
    <div class="button-container">
      <p-button
        label="Nueva gráfica"
        [loading]="loadingModal"
        (click)="showChartDialog()"
      ></p-button>
    </div>
  </div>

  <!-- Recorremos todas las gráficas que haya disponibles -->
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <!-- Aviso de incidencia con alguna gráfica -->
      @if(wrongChart){
      <div class="w-full justify-content-center align-items-center flex">
        <p-messages
          [(value)]="messages"
          [enableService]="false"
          [closable]="true"
          severity="warning"
        />
      </div>
      }
      <!--  -->
      @for(chart of chartArray; track chart){
      <!--  -->
      @if(chartArray.length === 1 && chart){
      <ion-col
        size-xs="12"
        size-sm="12"
        size-md="8"
        class="ion-align-self-center"
      >
        <ion-card class="card-esg">
          <ion-card-content>
            <div class="card-header">
              <p-button
                severity="danger"
                icon="pi pi-trash"
                pTooltip="Eliminar gráfica"
                class="p-button-rounded p-button-text p-button-danger"
                (click)="deleteChart($event, chart)"
              ></p-button>
            </div>
            <p-chart type="line" [data]="chart" [options]="options"></p-chart>
          </ion-card-content>
        </ion-card>
      </ion-col>
      }
      <!--  -->
      @if(chartArray.length > 1){
      <ion-col
        size-xs="12"
        size-sm="12"
        size-md="6"
        class="ion-align-self-center"
      >
        <ion-card class="card-esg">
          <ion-card-content>
            <div class="card-header">
              <p-button
                severity="danger"
                icon="pi pi-trash"
                pTooltip="Eliminar gráfica"
                class="p-button-rounded p-button-text p-button-danger"
                (click)="deleteChart($event, chart)"
              ></p-button>
            </div>
            <p-chart type="line" [data]="chart" [options]="options"></p-chart>
          </ion-card-content>
        </ion-card>
      </ion-col>
      }
      <!--  -->
      }
    </ion-row>
  </ion-grid>
  }
</ion-grid>

<!-- Modal para formulario de filtrado de gráficas -->
<p-dialog
  [(visible)]="dialogChart"
  header="Seleccione los parámetros para su gráfica"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '70vw' }"
  (onHide)="closeChartModal()"
>
  <ion-grid class="p-5">
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <label>Seleccione un indicador*</label>
        <p-listbox
          [options]="indicators"
          [(ngModel)]="selectedIndicator"
          optionLabel="name"
          [filter]="true"
          [style]="{width: '100%'}"
          (ngModelChange)="handleIndicator($event)"
        >
        </p-listbox>
      </ion-col>
      <ion-col size-xs="12" size-sm="12" size-md="6">
        <label>¿Cómo quiere mostrar los datos?*</label>
        <div class="mt-1 p-fluid">
          <p-selectButton
            [options]="chartOptions"
            optionValue="value"
            optionLabel="label"
            [(ngModel)]="showAccumulated"
            [style]="{ width: '100%' }"
          >
          </p-selectButton>
        </div>
      </ion-col>
      <ion-col size-xs="12" size-sm="12" size-md="6">
        <label>Posición que ocupará la gráfica*</label>
        <div class="mt-1 p-fluid">
          <p-inputNumber
            inputId="integeronly"
            placeholder="Seleccione una posición numérica"
            [(ngModel)]="chartPosition"
            [min]="1"
            [max]="999"
            [showButtons]="true"
          />
        </div>
      </ion-col>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <label>Seleccione el valor de eje Y para la gráfica*</label>
        <div class="mt-1 p-fluid">
          <p-dropdown
            [options]="chartTypeOptions"
            optionLabel="label"
            [(ngModel)]="chartType"
            placeholder="Seleccione una opción"
            appendTo="body"
          >
          </p-dropdown>
        </div>
      </ion-col>
      @if(showAccumulated !== true && showAccumulated !== null){
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <label>Seleccione que acciones quiere visualizar*</label>
        <div class="mt-1 p-fluid">
          <p-dropdown
            [options]="chartActionOptions"
            optionLabel="label"
            [(ngModel)]="selectedChartAction"
            placeholder="Seleccione una opción"
            appendTo="body"
          ></p-dropdown>
        </div>
      </ion-col>
      }
    </ion-row>
  </ion-grid>
  <ng-template pTemplate="footer">
    <p-button
      label="Guardar"
      [loading]="loadingForm"
      (click)="validateChartView()"
    >
    </p-button>
  </ng-template>
</p-dialog>
