import { Component, OnInit } from '@angular/core';
import { CompanyIdHeaderService } from '../../services/company-id-header.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { ServerService } from '../../services/server.service';
import { PermissionsService } from '../../services/permissions.service';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { Indicator } from 'src/app/models/indicator';
import { Action } from 'src/app/models/action';
import { Objective } from 'src/app/models/objetive';
import { InitialState } from 'src/app/models/initial-state';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {
  chartData: any;
  basicOptions: any;
  showChart: boolean = false;
  loading: boolean = true;
  companyId: string | null = null;
  user: any;
  userByCompany: any = [];
  dialogChart: boolean = false;
  chartType: any;
  chartTypeOptions: any[] = [];
  wrongChart: boolean = false;
  messages: Message[] | undefined;
  chartOptions: any = [
    {
      label: 'Acumulados',
      value: true,
    },
    {
      label: 'Desglosados',
      value: false,
    },
  ];
  chartActionOptions: any[] = [];
  showAccumulated: boolean | null = null;
  selectedChartAction: any | null = null;
  indicators: Indicator[] = [];
  selectedIndicator: Indicator | null = null;
  loadingModal: boolean = false;
  loadingForm: boolean = false;
  actions: Action[] = [];
  selectedObjectives: Objective[] = [];
  selectedInitialState: InitialState;
  options: any;
  chartArray: any[] = [];
  chartPosition: number | null;

  constructor(
    private companyIdService: CompanyIdHeaderService,
    private serverService: ServerService,
    public permissionService: PermissionsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
    this.companyIdService.getCompanyIdObservable().subscribe((companyId) => {
      this.companyId = companyId;
    });
  }

  async ngOnInit() {
    await this.getUserByCompany();
    await this.getChartDataByUser();
  }

  /**
   * Manejador de apertura de modal para definir una nueva gráfica
   */
  async showChartDialog() {
    this.closeChartModal();
    this.loadingModal = true;
    await this.getDropdownData();
    this.dialogChart = true;
    this.loadingModal = false;
  }

  /**
   * Obtención de datos necesarios para desplegables de formulario de filtrado de gráficas
   */
  async getDropdownData() {
    try {
      // Obtener indicadores
      const indicatorListResponse = await firstValueFrom(
        this.serverService.getData(`/api/indicators`)
      );

      if (indicatorListResponse.data) {
        indicatorListResponse.data = indicatorListResponse.data.filter(
          (indicator) =>
            indicator.indicator_configuration_id !== null &&
            ((indicator['initial_state'] &&
              indicator['initial_state'].length > 0) ||
              (indicator['objective'] && indicator['objective'].length > 0) ||
              (indicator['actions'] &&
                indicator['actions'].some(
                  (action) =>
                    action['evidences'] && action['evidences'].length > 0
                )))
        );
        this.indicators = indicatorListResponse.data;
      }
    } catch (err) {
      this.loadingModal = false;
      console.error('Error', err);
    }
  }

  /**
   * Manejador de indicador seleccionado y procesado de campos con caracter &
   * @param event
   */
  async handleIndicator(event: any) {
    // this.selectedIndicator = event.option;
    // Obtenemos campos con &
    const ampersandValues = await this.findAmpersandDataSelect(
      JSON.parse(this.selectedIndicator?.indicator_configuration?.config!!)
    );

    // Procesar datos para desplegable de selección de eje Y de gráficas
    this.chartTypeOptions = this.findAmpersandDataSelect(ampersandValues).map(
      (item) => {
        const key = Object.keys(item)[0]; // Obtener el nombre del campo (&Columna1_campo1, etc.)
        return {
          label: this.getCleanedKey(key), // El nombre del campo será el label del dropdown
        };
      }
    );

    // Obtengo las acciones de un indicador
    await this.getActionData();

    // Procesar datos de acciones, añado opción fija para todos las acciones
    this.chartActionOptions = [
      { id: 0, value: 'ALL', label: 'Todas las acciones' }, // Opción fija
      ...this.actions.map((action) => ({
        ...action,
        label: 'Acción #' + action.id,
        value: action.id,
      })),
    ];
    this.dialogChart = true;
  }

  /**
   * Obtener listado de acciones
   */
  async getActionData() {
    try {
      const response = await firstValueFrom(
        this.serverService.getData(
          '/api/indicator/' + this.selectedIndicator?.id + '/actions'
        )
      );

      if (response.data) {
        this.actions = response.data.map((item) => ({
          ...item,
          evidences: item.evidences
            .map((subitem) => ({
              ...subitem,
              data: JSON.parse(subitem.data),
            }))
            .sort(
              (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
            ), // Ordenar evidencias por fecha descendente
        }));
      }
    } catch (err) {
      console.error('Error al obtener datos de la acción', err);
    }
    return true;
  }

  /**
   * Función para eliminar terminación "_campo" de ciertos campos
   * @param key
   * @returns
   */
  getCleanedKey(key: string): string {
    if (key.includes('&')) {
      key = key.replace('&', ''); // Elimina el símbolo &
    }
    if (key.includes('_campo')) {
      key = key.split('_campo')[0]; // Elimina el sufijo '_campo'
    }
    return key.trim(); // Elimina cualquier espacio en blanco adicional
  }

  /**
   * Función para montar datos de selector de columnas eje Y de gráficas
   * @param obj
   * @returns
   */
  findAmpersandDataSelect(obj: any): any {
    let results: any[] = [];

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Si la clave incluye '&' y es un objeto (último nivel con valores dentro)
        if (key.includes('&') && typeof obj[key] === 'object') {
          results.push({ [key]: obj[key] });
        } else if (typeof obj[key] === 'object') {
          // Si la clave es un objeto, buscamos recursivamente
          const nestedResults = this.findAmpersandDataSelect(obj[key]);
          if (nestedResults.length > 0) {
            results = results.concat(nestedResults);
          }
        }
      }
    }
    return results;
  }

  /**
   * Obtención de parámetros de filtrado para construir gráficas
   */
  async getChartDataByUser() {
    try {
      // Obtener array de registros de gráficas de usuario y una a una llamar a updateChartView para ir construyendo el array de gráficas chartArray
      const chartDataResponse = await firstValueFrom(
        this.serverService.getData(`/api/indicator/chart-dashboard`)
      );

      // Si hay datos guardados, comenzamos a construir las gráficas
      if (chartDataResponse.data) {
        const chartData = chartDataResponse.data;
        chartData.sort((a, b) => a.position - b.position);

        // Ajustar las posiciones para eliminar duplicados
        const assignedPositions = new Set<number>();
        for (let data of chartData) {
          let desiredPosition = data.position;

          // Encontrar la siguiente posición disponible si la actual ya está asignada
          while (assignedPositions.has(desiredPosition)) {
            desiredPosition++;
          }

          // Asignar la nueva posición y agregarla al conjunto de posiciones asignadas
          data.position = desiredPosition;
          assignedPositions.add(desiredPosition);
        }

        // Ahora puedes llamar a handleChartData con posiciones únicas
        for (const data of chartData) {
          await this.handleChartData(data);
        }
      }
      this.loading = false;
    } catch (err) {
      this.loading = false;
      console.error('Error al obtener gráficas del usuario', err);
    }
  }

  /**
   * Función para adaptar correctamente los datos de cada registro de chartResponseData
   */
  async handleChartData(data) {
    this.selectedIndicator = data.indicator;
    await this.getActionData();

    // Si es > 0, es una acción indivual
    if (data.action > 0) {
      this.selectedChartAction = this.actions.find(
        (action) => action.id === data.action
      );
      this.selectedChartAction.label = 'Acción #' + data.action;
      this.selectedChartAction.value = data.action;
    } else if (data.action === 0 || !data.action) {
      // Todas las acciones
      this.selectedChartAction = {
        id: 0,
        value: 'ALL',
        label: 'Todas las acciones',
      };
    }
    this.showAccumulated = data.type === 0 ? true : false;
    this.chartType = {
      label: data.column,
    };

    await this.updateChartView(data.id, data.indicator);
  }

  /**
   * Obtener datos completos de usuario según empresa
   */
  async getUserByCompany() {
    try {
      // Esperamos a que se resuelva
      const userResponse = await lastValueFrom(
        this.serverService.getDataUsersApi('/api/user')
      );
      if (userResponse) {
        this.user = userResponse;
        // Esperamos a que se resuelva
        const userByCompany = await lastValueFrom(
          this.serverService.getDataUsersApi(
            `/api/companies/${this.companyId}/users/${this.user?.id}`
          )
        );
        if (userByCompany) {
          this.userByCompany = userByCompany.data ? userByCompany.data : [];
        }
      }
    } catch (err) {
      this.loading = false;
      console.error(
        'Error al obtener datos usuario y compaías de usuario:',
        err
      );
    }
  }

  /**
   * Validador de formulario de gráficas
   * @returns
   */
  validateChartView() {
    if (this.chartType) {
      if (!this.selectedIndicator) {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Debe seleccionar un indicador de la lista',
        });
      }

      // Verificar si la opción de acumulado ha sido seleccionada
      if (this.showAccumulated === null) {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Debe seleccionar si es acumulado o no',
        });
      }

      // Si no es acumulado, validar que se ha seleccionado una acción
      if (this.showAccumulated === false && !this.selectedChartAction) {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Debe seleccionar una acción para gráficos no acumulados',
        });
      }

      if (!this.chartPosition || this.chartPosition < 0) {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Debe seleccionar una posición para la gráfica',
        });
      }

      if (this.showAccumulated === true && this.chartType) {
        this.selectedChartAction = null;
      }

      // Si pasa las validaciones anteriores, actualizar la vista de la gráfica
      this.updateChartView();

      // Guardado de filtros de gráfica según el usuario e indicador y/o acción/acciones
      this.saveChartData();
    } else {
      // Si no se ha seleccionado el chartType
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Debe completar todos los campos del formulario',
      });
    }
  }

  /**
   * Obtener estado inicial y objetivos de un indicador
   */
  async getIndicatorData() {
    try {
      const indicatorResponse = await firstValueFrom(
        this.serverService.getData(
          `/api/indicators/${this.selectedIndicator?.id}`
        )
      );

      if (indicatorResponse) {
        this.selectedInitialState = indicatorResponse.data['initial_state'];
        this.selectedObjectives = indicatorResponse.data['objective'];
      }
    } catch (err) {
      console.error('Error al obtener datos del indicador', err);
    }
  }

  /**
   * Función auxiliar para transformar acción de objeto a array
   * @param action
   */
  actionObjectToArray(action) {
    return [action];
  }

  findAmpersandData(obj: any) {
    let results: any[] = [];
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key.includes('&') && typeof obj[key] === 'object') {
          results.push({ [key]: obj[key] });
        } else if (typeof obj[key] === 'object') {
          const nestedResults = this.findAmpersandData(obj[key]);
          if (nestedResults.length > 0) {
            results = results.concat(nestedResults);
          }
        }
      }
    }
    return results;
  }

  /**
   * Formatear datos de tablas a decimales y miles
   * @param dato
   * @returns
   */
  dataFormat(data: any, returnNumber: boolean = false): string | number {
    // Verificar y convertir el dato a número si es necesario
    let number: number;
    if (typeof data === 'number') {
      number = data;
    } else if (!isNaN(parseFloat(data)) && isFinite(data)) {
      number = parseFloat(data);
    } else {
      return data; // Retorna el dato sin cambios si no es numérico
    }

    // Truncar el número a dos decimales si no es entero
    if (!Number.isInteger(number)) {
      number = Math.floor(number * 100) / 100;
    }

    if (returnNumber) {
      return number;
    } else {
      // Opciones de formateo
      let options: Intl.NumberFormatOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 20,
        useGrouping: true,
      };

      // Verificar si el número tiene decimales
      if (!Number.isInteger(number)) {
        options.minimumFractionDigits = 2;
        options.maximumFractionDigits = 2;
      }

      return new Intl.NumberFormat('es-ES', options).format(number);
    }
  }

  /**
   * Función para rellenar valores anteriores si no hay datos para una fecha específica.
   */
  fillPreviousValues(dates: any[], data: { [date: string]: number }) {
    let previousValue = 0;
    return dates.map((date) => {
      if (data[date] !== undefined) {
        previousValue = data[date];
      }
      return previousValue;
    });
  }

  /**
   * Generador de gráfica según parametros de filtrados definidos en el formulario
   */
  async updateChartView(chartDashboardId?: number, indicator?: any) {
    // Obtener objetivos y estado inicial
    await this.getIndicatorData();

    let objectivesChartData = this.selectedObjectives;

    // Si hemos seleccionado todas las acciones las mostramos tal cual, sino solamente la seleccionada
    let actionChartData =
      (this.selectedChartAction && this.selectedChartAction.value === 'ALL') ||
      this.showAccumulated === true
        ? this.actions
        : this.actionObjectToArray(this.selectedChartAction);

    let initialStateChartData = this.selectedInitialState[0];

    let dates = new Set<any>();
    const dataValues = {};
    const objectiveDataValues = {};
    const initialStateDataValues = {};

    objectivesChartData = objectivesChartData.map((item) => ({
      ...item,
      data: JSON.parse(item.data),
    }));

    // Estado inicial
    if (initialStateChartData && initialStateChartData.data) {
      initialStateChartData.data =
        typeof initialStateChartData.data === 'string'
          ? JSON.parse(initialStateChartData.data)
          : initialStateChartData.data;

      // Procesar datos de gráfica de estado inicial
      if (this.showAccumulated) {
        // Datos acumulados
        let totalValuesByDate = {};
        dates.add(initialStateChartData.date);
        // Buscamos los campos con &
        let ampersandData;
        let ampersandDatas = this.findAmpersandData(initialStateChartData.data);
        ampersandData = ampersandDatas.find((item) => {
          const key = Object.keys(item)[0]; // Obtenemos el nombre de la clave en el objeto
          return key.includes(this.chartType.label); // Verificamos si la clave contiene el "label"
        });
        ampersandData = ampersandData ? Object.values(ampersandData)[0] : null;

        if (ampersandData) {
          for (let country in ampersandData) {
            if (ampersandData.hasOwnProperty(country) && country !== 'tipo') {
              if (!totalValuesByDate[initialStateChartData.date]) {
                totalValuesByDate[initialStateChartData.date] = 0;
              }
              // Si hay algún valor a null, lo interpreta como un 0 para sumar correctamente
              const value =
                ampersandData[country] !== null
                  ? (this.dataFormat(ampersandData[country], true) as number)
                  : 0;
              totalValuesByDate[initialStateChartData.date] += value;
            }
          }
        } else {
          this.wrongChart = true;
          this.messages = [
            {
              severity: 'warn',
              detail:
                'Se ha detectado un error al intentar mostrar una de las gráficas definidas, debiendose a diferencias entre estructuras de algún indicador cuantitativo y un formulario de estado inicial, objetivo o evidencias',
            },
          ];
        }
        initialStateDataValues['total'] = totalValuesByDate;
      } else {
        // Datos desglosados
        dates.add(initialStateChartData.date);
        let ampersandData;
        let ampersandDatas = this.findAmpersandData(initialStateChartData.data);
        ampersandData = ampersandDatas.find((item) => {
          const key = Object.keys(item)[0]; // Obtenemos el nombre de la clave en el objeto
          return key.includes(this.chartType.label); // Verificamos si la clave contiene el "label"
        });
        ampersandData = ampersandData ? Object.values(ampersandData)[0] : null;

        if (ampersandData) {
          for (let country in ampersandData) {
            if (ampersandData.hasOwnProperty(country) && country !== 'tipo') {
              if (!initialStateDataValues[country]) {
                initialStateDataValues[country] = {};
              }
              const value = this.dataFormat(
                ampersandData[country],
                true
              ) as number;
              initialStateDataValues[country][initialStateChartData.date] =
                value;
            }
          }
        } else {
          this.wrongChart = true;
          this.messages = [
            {
              severity: 'warn',
              detail:
                'Se ha detectado un error al intentar mostrar una de las gráficas definidas, debiendose a diferencias entre estructuras de algún indicador cuantitativo y un formulario de estado inicial, objetivo o evidencias',
            },
          ];
        }
      }
    }

    // Procesar datos de gráfica de objetivos
    // Datos acumulados
    if (this.showAccumulated) {
      let totalValuesByDate = {};
      objectivesChartData.forEach((item) => {
        dates.add(item.date);
        let ampersandData;
        let ampersandDatas = this.findAmpersandData(item.data);
        ampersandData = ampersandDatas.find((item) => {
          const key = Object.keys(item)[0]; // Obtenemos el nombre de la clave en el objeto
          return key.includes(this.chartType.label); // Verificamos si la clave contiene el "label"
        });
        ampersandData = ampersandData ? Object.values(ampersandData)[0] : null;

        if (ampersandData) {
          for (let country in ampersandData) {
            if (ampersandData.hasOwnProperty(country) && country !== 'tipo') {
              if (!totalValuesByDate[item.date]) {
                totalValuesByDate[item.date] = 0;
              }
              // Si hay algún valor a null, lo interpreta como un 0 para sumar correctamente
              const value =
                ampersandData[country] !== null
                  ? (this.dataFormat(ampersandData[country], true) as number)
                  : 0;
              totalValuesByDate[item.date] += value;
            }
          }
        } else {
          this.wrongChart = true;
          this.messages = [
            {
              severity: 'warn',
              detail:
                'Se ha detectado un error al intentar mostrar una de las gráficas definidas, debiendose a diferencias entre estructuras de algún indicador cuantitativo y un formulario de estado inicial, objetivo o evidencias',
            },
          ];
        }
      });
      objectiveDataValues['total'] = totalValuesByDate;
    } else {
      // Datos desglosados
      objectivesChartData.forEach((item) => {
        dates.add(item.date);
        let ampersandData;
        let ampersandDatas = this.findAmpersandData(item.data);
        ampersandData = ampersandDatas.find((item) => {
          const key = Object.keys(item)[0]; // Obtenemos el nombre de la clave en el objeto
          return key.includes(this.chartType.label); // Verificamos si la clave contiene el "label"
        });
        ampersandData = ampersandData ? Object.values(ampersandData)[0] : null;

        if (ampersandData) {
          for (let country in ampersandData) {
            if (ampersandData.hasOwnProperty(country) && country !== 'tipo') {
              if (!objectiveDataValues[country]) {
                objectiveDataValues[country] = {};
              }
              const value = this.dataFormat(
                ampersandData[country],
                true
              ) as number;
              objectiveDataValues[country][item.date] = value;
            }
          }
        } else {
          this.wrongChart = true;
          this.messages = [
            {
              severity: 'warn',
              detail:
                'Se ha detectado un error al intentar mostrar una de las gráficas definidas, debiendose a diferencias entre estructuras de algún indicador cuantitativo y un formulario de estado inicial, objetivo o evidencias',
            },
          ];
        }
      });
    }
    const sortedDates = Array.from(dates).sort();
    const datasets: any[] = [];

    // Procesar datos de gráfica de evidencias y su presentación en pantalla
    if (this.showAccumulated) {
      // Datos acumulados
      let accumulatedDataValues: {
        [country: string]: { [date: string]: number };
      } = {};
      let totalAccumulatedValues: { [country: string]: number } = {};
      let totalValuesByDate: { [date: string]: number } = {};

      actionChartData.forEach((action) => {
        if (action.evidences) {
          action.evidences.forEach((evidence) => {
            // Debe existir evaluations, es decir, que la evidencia haya sido evaluada
            if (
              evidence &&
              evidence['evaluations'].length > 0 &&
              evidence['evaluations'][0].efficacy === 1
            ) {
              dates.add(evidence.date);
              let ampersandData;
              let ampersandDatas = this.findAmpersandData(evidence.data);
              ampersandData = ampersandDatas.find((item) => {
                const key = Object.keys(item)[0];
                return key.includes(this.chartType.label);
              });
              ampersandData = ampersandData
                ? Object.values(ampersandData)[0]
                : null;

              if (ampersandData) {
                for (let country in ampersandData) {
                  if (
                    ampersandData.hasOwnProperty(country) &&
                    country !== 'tipo'
                  ) {
                    if (!totalValuesByDate[evidence.date]) {
                      totalValuesByDate[evidence.date] = 0;
                    }
                    // Si hay algún valor a null, lo interpreta como un 0 para sumar correctamente
                    const value =
                      ampersandData[country] !== null
                        ? (this.dataFormat(
                            ampersandData[country],
                            true
                          ) as number)
                        : 0;
                    totalValuesByDate[evidence.date] += value;

                    if (!accumulatedDataValues[country]) {
                      accumulatedDataValues[country] = {};
                      totalAccumulatedValues[country] = 0;
                    }
                    totalAccumulatedValues[country] += value;

                    accumulatedDataValues[country][evidence.date] =
                      totalAccumulatedValues[country];
                  }
                }
              } else {
                this.wrongChart = true;
                this.messages = [
                  {
                    severity: 'warn',
                    detail:
                      'Se ha detectado un error al intentar mostrar una de las gráficas definidas, debiendose a diferencias entre estructuras de algún indicador cuantitativo y un formulario de estado inicial, objetivo o evidencias',
                  },
                ];
              }
            }
          });
        }
      });

      // Sumar los valores acumulados de cada país por fecha
      let totalAccumulatedByDate: { [date: string]: number } = {};
      for (let date in totalValuesByDate) {
        if (totalValuesByDate.hasOwnProperty(date)) {
          if (!totalAccumulatedByDate[date]) {
            totalAccumulatedByDate[date] = 0;
          }
          totalAccumulatedByDate[date] += totalValuesByDate[date];
        }
      }
      datasets.push({
        label: 'Total Acumulado (Evidencias)',
        data: this.fillPreviousValues(
          Array.from(dates).sort(),
          totalAccumulatedByDate
        ),
        fill: false,
        borderColor: this.getRandomColor(),
        tension: 0.4,
      });
    } else {
      // Datos desglosados
      actionChartData.forEach((action) => {
        if (action.evidences) {
          action.evidences.forEach((evidence) => {
            if (
              evidence &&
              evidence['evaluations'].length > 0 &&
              evidence['evaluations'][0].efficacy === 1
            ) {
              dates.add(evidence.date);
              let ampersandData;
              let ampersandDatas = this.findAmpersandData(evidence.data);
              ampersandData = ampersandDatas.find((item) => {
                const key = Object.keys(item)[0];
                return key.includes(this.chartType.label);
              });
              ampersandData = ampersandData
                ? Object.values(ampersandData)[0]
                : null;

              if (ampersandData) {
                for (let country in ampersandData) {
                  if (
                    ampersandData.hasOwnProperty(country) &&
                    country !== 'tipo'
                  ) {
                    if (!dataValues[country]) {
                      dataValues[country] = {};
                    }
                    if (!dataValues[country][evidence.date]) {
                      dataValues[country][evidence.date] = 0;
                    }
                    const value = this.dataFormat(
                      ampersandData[country],
                      true
                    ) as number;
                    dataValues[country][evidence.date] += value;
                  }
                }
              } else {
                this.wrongChart = true;
                this.messages = [
                  {
                    severity: 'warn',
                    detail:
                      'Se ha detectado un error al intentar mostrar una de las gráficas definidas, debiendose a diferencias entre estructuras de algún indicador cuantitativo y un formulario de estado inicial, objetivo o evidencias',
                  },
                ];
              }
            }
          });
        }
      });

      for (let country in dataValues) {
        if (dataValues.hasOwnProperty(country) && country !== 'checked') {
          datasets.push({
            label: `${country} (Evidencias)`,
            data: this.fillPreviousValues(
              Array.from(dates).sort(),
              dataValues[country]
            ),
            fill: false,
            tension: 0.4,
            borderColor: this.getRandomColor(),
          });
        }
      }
    }

    // Procesar presentación por pantalla de datos de gráfica de estado inicial
    if (initialStateChartData && initialStateChartData.data) {
      if (this.showAccumulated) {
        let accumulatedData = this.fillPreviousValues(
          Array.from(dates).sort(),
          initialStateDataValues['total']
        );
        datasets.push({
          label: 'Total Acumulado (Estado inicial)',
          data: accumulatedData,
          fill: false,
          tension: 0.4,
          borderColor: this.getRandomColor(),
        });
      } else {
        // Datos desglosados
        for (let country in initialStateDataValues) {
          if (
            initialStateDataValues.hasOwnProperty(country) &&
            country !== 'checked'
          ) {
            datasets.push({
              label: `${country} (Estado inicial)`,
              data: this.fillPreviousValues(
                Array.from(dates).sort(),
                initialStateDataValues[country]
              ),
              fill: false,
              tension: 0.4,
              borderColor: this.getRandomColor(),
            });
          }
        }
      }
    }

    // Procesar presentación por pantalla de datos de gráfica de objetivos
    if (this.showAccumulated) {
      let accumulatedData = this.fillPreviousValues(
        Array.from(dates).sort(),
        objectiveDataValues['total']
      );
      datasets.push({
        label: 'Total Acumulado (Objetivos)',
        data: accumulatedData,
        fill: false,
        borderColor: this.getRandomColor(),
        tension: 0.4,
      });
    } else {
      // Datos desglosados
      for (let country in objectiveDataValues) {
        if (
          objectiveDataValues.hasOwnProperty(country) &&
          country !== 'checked'
        ) {
          datasets.push({
            label: `${country} (Objetivos)`,
            data: this.fillPreviousValues(
              Array.from(dates).sort(),
              objectiveDataValues[country]
            ),
            fill: false,
            tension: 0.4,
            borderColor: this.getRandomColor(),
          });
        }
      }
    }

    // Procesado de gráfica tras procesado de datos
    this.chartData = {
      chartDashboardId: chartDashboardId,
      indicatorId: indicator.id,
      labels: Array.from(dates).sort(),
      datasets: datasets,
    };

    // Opciones de la gráfica
    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 1,
      scales: {
        y: {
          beginAtZero: true,
          suggestedMax: this.calculateMaxValue(datasets) + 2,
          title: {
            display: true,
            text: this.chartType.label,
          },
        },
        x: {
          title: {
            display: true,
            text: 'Fechas de evidencias',
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              const value = context.parsed.y;
              // Usamos dataFormat para formatear el valor en el tooltip
              return `${context.dataset.label}: ${this.dataFormat(value)}`;
            },
          },
        },
      },
    };

    // Total de gráficas
    this.chartArray.push(this.chartData);
  }

  /**
   * Guardar datos de formulario con parámetros de filtrado de gráfico
   */
  async saveChartData() {
    const data: any = {
      indicator_id: this.selectedIndicator?.id,
      user_id: this.user.id,
      type: this.showAccumulated ? 0 : 1,
      column: this.chartType.label,
      action: this.selectedChartAction
        ? this.selectedChartAction === 'ALL'
          ? 0
          : this.selectedChartAction.id
        : undefined,
      position: this.chartPosition,
    };

    this.loadingForm = true;

    this.serverService
      .sendData(
        `/api/indicator/${this.selectedIndicator?.id}/chart-dashboard`,
        data
      )
      .subscribe({
        next: async (response) => {
          if (response.data) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Los datos de la gráfica se han guardado exitosamente',
            });
            await this.getChartDataByUser();
            this.loadingForm = false;
            this.dialogChart = false;
          }
        },
        error: (err) => {
          console.error('Error al guardar parámetros para la gráfica', err);
          this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            detail: 'Error al guardar la gráfica, inténtelo de nuevo',
          });
          this.loadingForm = false;
        },
      });
  }

  /**
   * Eliminación de gráfica pulsada
   */
  deleteChart(event, chart) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: '¿Está seguro de eliminar esta gráfica?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.serverService
          .deleteData(
            `/api/indicator/${chart.indicatorId}/chart-dashboard/${chart.chartDashboardId}`
          )
          .subscribe({
            next: (response) => {
              if (response.data) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'OK',
                  detail: 'Gráfica eliminada correctamente',
                });
                // Actualizamos visor de gráficas de manera local
                this.chartArray = this.chartArray.filter(
                  (item) => item.chartDashboardId !== chart.chartDashboardId
                );
              }
            },
            error: (err) => {
              console.error('Error al eliminar la gráfica', err);
              this.messageService.add({
                severity: 'warn',
                summary: 'Aviso',
                detail: 'Error al eliminar la gráfica, inténtelo de nuevo',
              });
            },
          });
      },
      reject: () => {},
    });
  }

  /**
   * Manejador de cierre de modal de gráficas
   */
  closeChartModal() {
    this.showAccumulated = null;
    this.selectedIndicator = null;
    this.selectedChartAction = null;
    this.chartType = null;
    this.chartPosition = null;
  }

  /**
   * Generador automático de colores para la gráfica
   * @returns
   */
  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  /**
   * Función para calcular el valor máximo de eje Y actual
   * @param datasets
   * @returns
   */
  calculateMaxValue(datasets: any[]): number {
    let allValues: number[] = [];

    datasets.forEach((dataset) => {
      if (dataset.data && Array.isArray(dataset.data)) {
        allValues = allValues.concat(
          dataset.data.map((value) => {
            // Convertir NaN, null, y valores no numéricos a 0
            return value === null || isNaN(value) ? 0 : value;
          })
        );
      }
    });

    // Aseguramos que no esté vacío y devolvemos el valor máximo, o 0 si no hay datos
    return allValues.length > 0 ? Math.max(...allValues) : 0;
  }
}
