import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../layout/service/app.layout.service';
import { ServerService } from '../../services/server.service';
import { Indicator } from '../../models/indicator';
import { Router } from '@angular/router';
import { filter, firstValueFrom } from 'rxjs';
import { MessageService } from 'primeng/api';
import { indicatorCertificate, indicatorGap, indicatorState, indicatorType, wordFilterOptions } from 'src/app/utils';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.page.html',
  styleUrls: ['./panel.page.scss'],
})
export class PanelPage implements OnInit {
  public basicOptions: any;
  filteredModel: any[] = [];
  model: any[] = [];
  menuData: any;
  companyId: any;
  indicators: Indicator[] = [];
  filteredIndicators: any[] = [];
  itemFromUrl: any;
  data: any;
  icon: string | undefined;
  options: any;
  indicatorId: number = 0;
  loadingWord: boolean = false;
  dialogFilter: boolean = false;
  loadingModal: boolean = false;
  rangeDates: any[] = [];
  loading: boolean = false;
  totales: object = {};
  indicatorCertificate = indicatorCertificate;
  wordFilterOptions = wordFilterOptions;
  selectedFilter: number | null = null;
  chartPieData: any;
  chartBarData: any;
  chartBar2Data: any;
  chartBarOptions: any;
  chartBar2Options: any;
  dataGraphicBar: any;
  dataGraphicBar2: any;
  dataTable: any;
  userByCompany: any;
  dialogIndicatorFilter: boolean = false;
  // Filtrado de indicadores por estado, tipo o aplicabilidad
  indicatorStateToFilter: string;
  indicatorGapToFilter: string;
  indicatorTypeToFilter: string;
  // Totales globales
  // A
  totalIndicators: number = 0;
  totalQualitatives: number = 0;
  totalQuantitatives: number = 0;
  totalNoType: number = 0;
  // C
  totalAppliesGap: number = 0;
  totalAppliesNoTreat: number = 0;
  totalNoAppliesGap: number = 0;
  totalVolunteers: number = 0;
  totalNoAnalyzed: number = 0;
  // B
  totalNoAppliesState: number = 0;
  totalValidated: number = 0;
  totalNoValidated: number = 0;
  //
  user: any;
  filteredIndicatorsByDates: any[] = [];
  labelMapping: any = {
    NO_APPLIES_STATE: 'No aplica',
    VALIDATED: 'Validado',
    NO_VALIDATED: 'No validado',
    NO_TYPE: 'Sin tipificar',
    //
    APPLIES_GAP: 'Aplica',
    APPLIES_NO_TREATED: 'Aplica y no trata',
    NO_APPLIES_GAP: 'No aplica',
    VOLUNTEER: 'Voluntario',
    NO_ANALYZED: 'Sin analizar',
    //
    qualitative: 'Cualitativo',
    quantitative: 'Cuantitativo',
    gap: 'Sin tipificar - con aplicabilidad',
    noType: 'Sin tipificar'
  };
  legendMapping: any = {
    'ESRS - Requisitos Mínimos de Divulgación (MDR)': 'ESRS-MDR',
    'ESRS 2 - Requisitos Generales de Divulgación': 'ESRS 2',
    'ESRS E1 - Cambio Climático': 'ESRS E1',
    'ESRS E2 - Contaminación': 'ESRS E2',
    'ESRS E3 - Agua y Recursos Marinos': 'ESRS E3',
    'ESRS E4 - Biodiversidad y Ecosistemas': 'ESRS E4',
    'ESRS E5 - Uso de Recursos y Economía Circular': 'ESRS E5',
    'ESRS S1 - Trabajadores Propios': 'ESRS S1',
    'ESRS S2 - Trabajadores en la cadena de valor': 'ESRS S2',
    'ESRS S3 - Comunidades afectadas': 'ESRS S3',
    'ESRS S4 - Consumidores': 'ESRS S4',
    'ESRS G1 - Conducta Empresarial': 'ESRS G1',
    'Alcance 1': 'Alcance 1',
    'Alcance 2': 'Alcance 2',
  };
  totalsByState: { [key: string]: number } = {};
  // Para desplegables
  indicatorState = indicatorState;
  indicatorGap = indicatorGap;
  indicatorType = indicatorType;
  selectedTypeFilter: string | null = null;
  selectedGapFilter: string | null = null;
  selectedStateFilter: string | null = null;

  constructor(
    public layoutService: LayoutService,
    private serverService: ServerService,
    private router: Router,
    private messageService: MessageService,
    public permissionService: PermissionsService
  ) {}

  async ngOnInit() {
    try {
      this.companyId = sessionStorage.getItem('companyId');
      await this.getUserByCompany();
      await this.recuperarDatosMenu();
      this.showGraphic('bar');
    } catch (error) {
      console.error('Error al recuperar los datos del menú:', error);
    }
  }

  /**
   * Mapear estado para gráfica
   * @param state
   * @returns
   */
  mapStateLabel(state: string): string {
    // Devuelve el valor mapeado si existe, o el estado original si no está mapeado
    return this.labelMapping[state] || state;
  }

  /**
   * Obtener datos sobre secciones y roles del usuario con sus permisos
   */
  async getUserByCompany() {
    try {
      // Esperamos a que se resuelva
      const userResponse = await firstValueFrom(
        this.serverService.getDataUsersApi('/api/user')
      );
      if (userResponse) {
        this.user = userResponse;
        // Esperamos a que se resuelva
        const userByCompany = await firstValueFrom(
          this.serverService.getDataUsersApi(
            `/api/companies/${this.companyId}/users/${this.user?.id}`
          )
        );
        if (userByCompany) {
          this.userByCompany = userByCompany.data ? userByCompany.data : [];
        }
      }
    } catch (err) {
      console.error(
        'Error al obtener datos usuario y compaías de usuario:',
        err
      );
    }
  }

  actualizarTotales(
    nivel: any,
    totalIndicators: number,
    cualitativos: number,
    cuantitativos: number,
    noType: number,
    // 
    noApplicatedState: number,
    validated: number,
    noValidated: number,
    //
    appliesGap: number,
    appliesNoTreat: number,
    noAppliesGap: number,
    volunteer: number,
    noAnalyzed: number

  ) {
    nivel.total += totalIndicators;
    nivel.cualitativos += cualitativos;
    nivel.cuantitativos += cuantitativos;
    nivel.noType += noType;
    // 
    nivel.noApplicatedState += noApplicatedState;
    nivel.validated += validated;
    nivel.noValidated += noValidated;
    //
    nivel.appliesGap += appliesGap;
    nivel.appliesNoTreat += appliesNoTreat;
    nivel.noAppliesGap += noAppliesGap;
    nivel.volunteers += volunteer;
    nivel.noAnalyzed += noAnalyzed;
  }

  inicializarTotales() {
    return {
      total: 0,
      cualitativos: 0,
      cuantitativos: 0,
      noType: 0,
      //
      noApplicatedState: 0,
      validated: 0,
      noValidated: 0,
      //
      appliesGap: 0,
      appliesNoTreat: 0,
      noAppliesGap: 0,
      volunteers: 0,
      noAnalyzed: 0
    };
  }

  /**
   * Recupera los datos del menú desde el servidor según un id de menú dado
   */
  async recuperarDatosMenu() {
    // Inicializar conteo total de indicadores
    this.totalIndicators = 0;

    // Obtener id de menú a partir de id de empresa
    const response = await firstValueFrom(
      this.serverService.getData(
        '/api/menusWithIndicatorName/' + this.companyId
      )
    );
    this.model = response.data;

    // Recorrer indicadores
    await this.getIndicators();

    // Inicializar datos para los gráficos
    this.dataGraphicBar = { label: [], value: [] };

    // Recorrer menú y calcular totales
    this.model['items'].forEach((padre: any) => {
      this.dataGraphicBar.label.push(padre.label);
      this.totales[padre.label] = this.inicializarTotales();

      // Recorrer nivel padre
      padre.items.forEach((hijo: any) => {
        this.totales[padre.label][hijo.label] = this.inicializarTotales();

        // Recorrer nivel hijo
        hijo.items.forEach((nieto: any) => {
          this.totales[padre.label][hijo.label][nieto.label] =
            this.inicializarTotales();

          let totalIndicators = 0;
          // Contadores para cualitativos y cuantitativos
          let cualitativos = 0,
            cuantitativos = 0,
            noType = 0,
            noApplicatedState = 0,
            validated = 0,
            noValidated = 0,
            appliesGap = 0,
            appliesNoTreat = 0,
            noAppliesGap = 0,
            volunteers = 0,
            noAnalyzed = 0;

          // Recorrer indicadores
          nieto.indicatorNames.forEach((indicator: any) => {
            if (indicator.type === 'qualitative') {
              cualitativos++;
              if (indicator.state === 'NO_APPLIES_STATE') noApplicatedState++;
              if (indicator.state === 'VALIDATED') validated++;
              if (indicator.state === 'NO_VALIDATED') noValidated++;
              if (indicator.gapState === 'APPLIES_GAP') appliesGap++;
              if (indicator.gapState === 'APPLIES_NO_TREATED') appliesNoTreat++;
              if (indicator.gapState === 'NO_APPLIES_GAP') noAppliesGap++;
              if (indicator.gapState === 'VOLUNTEER') volunteers++;
              if (indicator.gapState === 'NO_ANALYZED') noAnalyzed++;
            } else if (indicator.type === 'quantitative') {
              cuantitativos++;
              if (indicator.state === 'NO_APPLIES_STATE') noApplicatedState++;
              if (indicator.state === 'VALIDATED') validated++;
              if (indicator.state === 'NO_VALIDATED') noValidated++;
              if (indicator.gapState === 'APPLIES_GAP') appliesGap++;
              if (indicator.gapState === 'APPLIES_NO_TREATED') appliesNoTreat++;
              if (indicator.gapState === 'NO_APPLIES_GAP') noAppliesGap++;
              if (indicator.gapState === 'VOLUNTEER') volunteers++;
              if (indicator.gapState === 'NO_ANALYZED') noAnalyzed++;
            } else if (indicator.type === 'noType' || indicator.type === 'gap') {
              noType++;
              if (indicator.gapState === 'APPLIES_GAP') appliesGap++;
              if (indicator.gapState === 'APPLIES_NO_TREATED') appliesNoTreat++;
              if (indicator.gapState === 'NO_APPLIES_GAP') noAppliesGap++;
              if (indicator.gapState === 'VOLUNTEER') volunteers++;
              if (indicator.gapState === 'NO_ANALYZED') noAnalyzed++;
            }
            totalIndicators++;
          });

          // Acumular total global de indicadores y tipos (grupo A)
          this.totalIndicators += totalIndicators;
          this.totalQualitatives += cualitativos;
          this.totalQuantitatives += cuantitativos;
          this.totalNoType += noType;
          // Acumular por aplicablidad (grupo C)
          this.totalAppliesGap += appliesGap;
          this.totalAppliesNoTreat += appliesNoTreat;
          this.totalNoAppliesGap += noAppliesGap;
          this.totalVolunteers += volunteers;
          this.totalNoAnalyzed += noAnalyzed;
          // Acumular por estados (grupo B)
          this.totalValidated += validated;
          this.totalNoValidated += noValidated;
          this.totalNoAppliesState += noApplicatedState;
          
          // Acumular totales en los niveles correspondientes
          this.actualizarTotales(
            this.totales[padre.label],
            totalIndicators,
            cualitativos,
            cuantitativos,
            noType,
            noApplicatedState,
            validated,
            noValidated,
            appliesGap,
            appliesNoTreat,
            noAppliesGap,
            volunteers,
            noAnalyzed,
          );

          this.actualizarTotales(
            this.totales[padre.label][hijo.label],
            totalIndicators,
            cualitativos,
            cuantitativos,
            noType,
            noApplicatedState,
            validated,
            noValidated,
            appliesGap,
            appliesNoTreat,
            noAppliesGap,
            volunteers,
            noAnalyzed,
          );
          this.actualizarTotales(
            this.totales[padre.label][hijo.label][nieto.label],
            totalIndicators,
            cualitativos,
            cuantitativos,
            noType,
            noApplicatedState,
            validated,
            noValidated,
            appliesGap,
            appliesNoTreat,
            noAppliesGap,
            volunteers,
            noAnalyzed,
          );
        });
      });

      // Agregar valores para gráfico de barras (Estados)
      const totalIndicatorsByParent = this.totales[padre.label].total;
      const totalValidatesByParent = this.totales[padre.label].validated;
      const totalNoValidatesByParent = this.totales[padre.label].noValidated;
      const totalNoApplicatedState = this.totales[padre.label].noApplicatedState;
      const totalNoType = this.totales[padre.label].noType;

      this.dataGraphicBar.value.push([
        totalValidatesByParent,
        totalNoValidatesByParent,
        totalNoApplicatedState,
        totalNoType,
      ]);
    });

    // Generar segundo gráfico de barras
    await this.bar2ChartGenerate();

    // Generar tabla de niveles con estados y porcenajes
    await this.generateLevelsTable();

    // Ocultar carga
    this.loading = true;
    return true;
  }

  /**
   * Aplicar filtrado de indicadores por tipo y estado
   */
  showIndicatorsFilterDialog(){
    this.dialogIndicatorFilter = true;
  }

  /**
   * Resetear filtro de indicadores
   */
  resetFilter(){
    this.filteredModel = [];
    this.selectedTypeFilter = null;
    this.selectedStateFilter = null;
    this.selectedGapFilter = null;
  }

  /**
   * Botón para aplicar filtrado de indicadores
   */
  onSubmitIndicadorFilter(){
    const typeFilter = this.selectedTypeFilter;
    const stateFilter = this.selectedStateFilter;
    const gapStateFilter = this.selectedGapFilter;

    let filteredModel = this.filterItemsByIndicators(typeFilter, stateFilter, gapStateFilter);
    this.filteredModel = filteredModel;
    this.dialogIndicatorFilter = false;

    // Aviso al filtrar
    if(filteredModel.length > 0){
      this.messageService.add({
        severity:'success',
        summary: 'Indicadores filtrados',
        detail: 'Se han aplicado los filtros de indicadores.',
      })
    } else {
      this.resetFilter();
      this.messageService.add({
        severity: 'warn',
        summary: 'No hay indicadores que cumplan los filtros',
        detail: 'No hay indicadores que satisfagan sus criterios de tipo y estado.',
      })
    }
  }

  /**
   * Aplicación de filtros definidos
   * @param typeFilter 
   * @param stateFilter 
   * @param gapStateFilter 
   * @returns 
   */
  filterItemsByIndicators(typeFilter: string | null, stateFilter: string | null, gapStateFilter: string | null) {
    function recursiveFilter(items: any[]): any[] {
      return items
        .map(item => {
          const filteredIndicators = item.indicatorNames?.filter((indicator: any) => {
            const typeMatch = typeFilter ? indicator.type === typeFilter : true;
            const stateMatch = stateFilter ? indicator.state === stateFilter : true;
            const gapStateMatch = gapStateFilter ? indicator.gapState === gapStateFilter : true;
            return typeMatch && stateMatch && gapStateMatch;
          });
  
          const filteredChildren = item.items ? recursiveFilter(item.items) : [];
  
          if ((filteredIndicators && filteredIndicators.length > 0) || (filteredChildren && filteredChildren.length > 0)) {
            return { ...item, indicatorNames: filteredIndicators, items: filteredChildren };
          }
          return null;
        })
        .filter(item => item !== null);
    }
  
    return recursiveFilter(this.model['items']);
  }

  /**
   * Generar tabla para niveles con sus estados de indicadores y
   */
  generateLevelsTable() {
    const tableData: any[] = [];

    this.model['items'].forEach((parentItem: any) => {
      const parentLabel = parentItem.label;

      // Objeto para contar los indicadores por estado en este nivel
      const stateCounts = {};
      let totalIndicators = 0;

      // Recorrer cada subnivel
      parentItem.items?.forEach((subItem: any) => {
        subItem.items?.forEach((indicator: any) => {
          // Agrupar por estado
          indicator['indicatorNames']?.forEach((indicatorDetail: any) => {
            const state = indicatorDetail.state;

            // Inicializar el conteo si no existe
            if (!stateCounts[state]) {
              stateCounts[state] = 0;
            }
            stateCounts[state]++;
            totalIndicators++;
          });
        });
      });

      // Calcular el porcentaje por cada estado
      const statePercentages = {};
      Object.keys(stateCounts).forEach((state) => {
        statePercentages[state] = (
          (stateCounts[state] / totalIndicators) *
          100
        ).toFixed(2); // Porcentaje
      });

      // Agregar el objeto de datos procesados a la tabla
      tableData.push({
        parentLabel,
        ...stateCounts,
        total: totalIndicators,
        statePercentages,
      });
    });

    // Asignar la estructura generada a una variable para mostrarla en la plantilla
    this.dataTable = tableData;
  }

  /**
   * Obtener listado de los estado de manera única
   * @returns
   */
  getUniqueStates() {
    const states = new Set<string>();
    this.dataTable?.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (
          key !== 'parentLabel' &&
          key !== 'total' &&
          key !== 'statePercentages'
        ) {
          // Excluir campos innecesarios
          states.add(key);
        }
      });
    });
    return Array.from(states);
  }

  /**
   * Recorrer indicadores y asignarle el estado que corresponda
   */
  async getIndicators() {
    let items: any[] = this.model['items'];
    // Recorrer menú e indicadores en niveles más profundos
    for (let item of items) {
      // Nivel hijo
      for (let subitem of item['items']) {
        // Nivel nieto
        for (let subsubitem of subitem['items']) {
          // Recorrer indicadores
          for (let indicator of subsubitem['indicatorNames']) {
            // Mapear indicadores y definir su estado actual de forma asíncrona
            [indicator.state, indicator.gapState] =
              await this.setIndicatorState(indicator);
          }
        }
      }
    }
  }

  /**
   * Función para generar el segundo gráfico de barras
   */
  async bar2ChartGenerate() {
    this.chartBar2Options = {
      maintainAspectRatio: false,
      aspectRatio: 0.5,
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Conteo de Indicadores por Estado y Nivel Padre',
        },
      },
      scales: {
        x: {
          stacked: true,
          title: {
            display: true,
            text: 'Estados',
          },
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: 'Cantidad de Indicadores',
          },
          beginAtZero: true,
        },
      },
    };

    const processedData = this.processDataForChart();
    this.totalsByState = this.calculateTotals(processedData);
    this.dataGraphicBar2 = this.generateBarChartData(processedData);
  }

  /**
   * Calcular valores ttotales de estados
   * @param processedData
   * @returns
   */
  calculateTotals(processedData) {
    const totals = {};

    Object.keys(processedData).forEach((state) => {
      totals[state] = 0;
      Object.values(processedData[state]).forEach((value) => {
        totals[state] += value; // Sumamos el total de cada estado
      });
    });

    return totals;
  }

  /**
   * Conteo de indicadores según nivel padre y su estado
   * @returns
   */
  processDataForChart() {
    const indicatorDataByState = {};

    // Recorremos todos los niveles (padre, hijo y nieto)
    this.model['items'].forEach((parentItem: any) => {
      parentItem.items?.forEach((childItem: any) => {
        childItem.items?.forEach((grandChildItem: any) => {
          grandChildItem.indicatorNames?.forEach((indicator: any) => {
            const state = indicator.state;
            const parentLabel = parentItem.label; // El nombre del nivel padre

            // Si no existe una entrada para este estado, la creamos
            if (!indicatorDataByState[state]) {
              indicatorDataByState[state] = {};
            }

            // Si no existe una entrada para este nivel padre, la inicializamos en 0
            if (!indicatorDataByState[state][parentLabel]) {
              indicatorDataByState[state][parentLabel] = 0;
            }

            // Contamos el indicador bajo ese estado y nivel padre
            indicatorDataByState[state][parentLabel]++;
          });
        });
      });
    });

    return indicatorDataByState;
  }

  /**
   * Procesado de datos para mostrar la gráfica de estados
   * @param processedData
   * @returns
   */
  generateBarChartData(processedData) {
    const chartData: any = {
      labels: [], // Etiquetas del eje X (mapeadas)
      datasets: [], // Conjuntos de datos por nivel padre
    };

    // Obtenemos todos los estados
    const allStates = Object.keys(processedData);

    // Mapeamos las etiquetas de los estados y les añadimos los totales desde totalsByState
    chartData.labels = allStates.map((state) => {
      const mappedLabel = this.mapStateLabel(state); // Mapeo visual
      const totalForState = this.totalsByState[state] || 0; // Total del estado
      return `${mappedLabel} - (Total: ${totalForState})`;
    });

    // Obtener los nombres de todos los niveles padres
    const allParentLabels = Array.from(
      new Set(
        allStates
          .map((state) => Object.keys(processedData[state]))
          .reduce((acc, val) => acc.concat(val), [])
      )
    );

    // Creamos un dataset para cada nivel padre
    allParentLabels.forEach((parentLabel) => {
      const parentData: any = {
        label: this.legendMapping[parentLabel] || parentLabel,
        data: [], // Conteos de indicadores por estado
      };

      // Rellenamos los datos para cada estado (sin mapear los datos, solo las etiquetas)
      allStates.forEach((state) => {
        parentData.data.push(processedData[state][parentLabel] || 0); // Si no hay datos, ponemos 0
      });

      chartData.datasets.push(parentData);
    });

    return chartData;
  }

  /**
   * Obtenemos mapeado el estado correspondiente
   * @param mappedLabel
   * @returns
   */
  getTotalForMappedLabel(mappedLabel: string): number {
    // Encuentra el estado original cuyo mapeo coincide con el label visual
    const originalState: any = Object.keys(this.labelMapping).find(
      (key) => this.labelMapping[key] === mappedLabel
    );

    // Si encuentra un estado mapeado, devuelve su total, si no, devuelve 0
    return this.totalsByState[originalState] || 0;
  }

  /**
   * Obtener ultimo registro de validacion de indicador
   */
  getLastValidation(indicator) {
    let lastAudited = indicator['relations']['indicatorValidation'].sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )[0];

    return lastAudited;
  }

  /**
   * Obtener ultimo registro de aplicabilidad
   */
  getLastGap(indicator) {
    let lastGap = indicator['relations']['history_indicator_gap'].sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )[0];

    return lastGap;
  }

  /**
   * Definir estado del indicador
   * @param indicator
   */
  setIndicatorState(indicator) {
    // indicator.validated ==> último documento ha sido validado o última evidencia validada
    // voluntario ==> no aplica pero si trata

    // Cuantitativo o cualitativo
    if(indicator.type === 'qualitative' || indicator.type === 'quantitative'){
      // NO APLICA ==> su último GAP no aplica y no trata
      if(this.getLastGap(indicator) &&
        !this.getLastGap(indicator).is_applicated &&
        !this.getLastGap(indicator).is_treated){
          return ['NO_APPLIES_STATE', 'NO_APPLIES_GAP'];
      }

      // VALIDADO y VOLUNTARIO
      if((indicator.validated && 
        this.getLastGap(indicator) &&
        !this.getLastGap(indicator).is_applicated &&
        this.getLastGap(indicator).is_treated)){
          return ['VALIDATED', 'VOLUNTEER'];
      } else if (indicator.validated && // VALIDADO Y NO APLICA
        this.getLastGap(indicator) &&
        !this.getLastGap(indicator).is_applicated &&
        !this.getLastGap(indicator).is_treated){
          return ['VALIDATED', 'NO_APPLIES_GAP'];
      } else if(indicator.validated && // VALIDADO y APLICA/NO TRATA
        this.getLastGap(indicator) &&
        !this.getLastGap(indicator).is_applicated &&
        this.getLastGap(indicator).is_treated){
          return ['VALIDATED', 'APPLIES_NO_TREATED'];
      } else if(indicator.validated &&  // VALIDADO y APLICA
        this.getLastGap(indicator) &&
        this.getLastGap(indicator).is_applicated &&
        this.getLastGap(indicator).is_treated){
          return ['VALIDATED', 'APPLIES_GAP'];
      } else if(indicator.validated && // VALIDADO y NO HAY REGISTRO
        !this.getLastGap(indicator)){
          return ['VALIDATED', 'NO_ANALYZED'];
      }

      // NO VALIDADO y VOLUNTARIO
      if((!indicator.validated && 
        this.getLastGap(indicator) &&
        !this.getLastGap(indicator).is_applicated &&
        this.getLastGap(indicator).is_treated)){
          return ['NO_VALIDATED', 'VOLUNTEER'];
      } else if (!indicator.validated && // NO VALIDADO Y NO APLICA
        this.getLastGap(indicator) &&
        !this.getLastGap(indicator).is_applicated &&
        !this.getLastGap(indicator).is_treated){
          return ['NO_VALIDATED', 'NO_APPLIES_GAP'];
      } else if(!indicator.validated && // NO VALIDADO y APLICA/NO TRATA
        this.getLastGap(indicator) &&
        !this.getLastGap(indicator).is_applicated &&
        this.getLastGap(indicator).is_treated){
          return ['NO_VALIDATED', 'APPLIES_NO_TREATED'];
      } else if(!indicator.validated &&  // NO VALIDADO y APLICA
        this.getLastGap(indicator) &&
        this.getLastGap(indicator).is_applicated &&
        this.getLastGap(indicator).is_treated){
          return ['NO_VALIDATED', 'APPLIES_GAP'];
      } else if(!indicator.validated && // NO VALIDADO y NO HAY REGISTRO
        !this.getLastGap(indicator)){
          return ['NO_VALIDATED', 'NO_ANALYZED'];
      }
    }

    // NULL O GAP
    if(indicator.type === 'noType' || indicator.type === 'gap'){
      if( // Gap SI/SI
        this.getLastGap(indicator) &&
        this.getLastGap(indicator).is_applicated &&
        this.getLastGap(indicator).is_treated){
          return ['NO_TYPE', 'APPLIES_GAP'];
      } else if ( // Gap NO/SI
        this.getLastGap(indicator) &&
        !this.getLastGap(indicator).is_applicated &&
        this.getLastGap(indicator).is_treated){
          return ['NO_TYPE', 'VOLUNTEER'];
      } else if ( // Gap SI/NO
        this.getLastGap(indicator) &&
        this.getLastGap(indicator).is_applicated &&
        !this.getLastGap(indicator).is_treated){
          return ['NO_TYPE', 'APPLIES_NO_TREATED'];
      } else if ( // Gap NO/NO
        this.getLastGap(indicator) &&
        !this.getLastGap(indicator).is_applicated &&
        !this.getLastGap(indicator).is_treated){
          return ['NO_TYPE', 'NO_APPLIES_GAP'];
      } else if(!this.getLastGap(indicator)){
        return ['NO_TYPE', 'NO_ANALYZED'];
      }
    }

    return ['ERROR', 'ERROR'];
  }

  redirect(indicator) {
    if (indicator.type === 'qualitative') {
      this.router.navigate([`base-qualitative/${indicator.id}`]);
    }
    if (indicator.type === 'quantitative') {
      let urlIndicator = indicator.special
        ? `base-indicator-special/${indicator.id}`
        : `base-indicator/${indicator.id}`;
      this.router.navigate([urlIndicator]);
    }
  }

  /**
   * Función para parsear la respuesta JSON que devuelve el servidor.
   * @param {Object} response - Respuesta devuelta por el servidor.
   * @returns {Array} - Array con los elementos del menú.
   */
  parsearRespuesta(response) {
    return JSON.parse(response.data.json_menu);
  }

  downloadXBRL(item) {
    console.log(item);
  }

  /**
   * Manejador de modal de filtro de indicadores y obtengo todos los indicadores
   */
  handleFilterModal() {
    this.loadingModal = true;
    this.serverService.getData('/api/getAllWithActions').subscribe({
      next: (response) => {
        if (response.data) {
          this.loadingModal = false;
          this.dialogFilter = true;
          this.filteredIndicators = response.data;
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            detail: 'No hay indicadores registrados para aplicar filtrados',
          });
        }
      },
      error: (err) => {
        console.error('Error al obtener indicadores', err);
      },
    });
  }

  /**
   * Descargar archivo Word de los indicadores, según filtro seleccionado
   */
  async onClickDownloadWord() {
    // Todos los indicadores
    if (this.selectedFilter === 1) {
      this.loadingWord = true;
      let arrayIndicatorId: (number | undefined)[] = [],
        jsonIndicators: string;
      this.filteredIndicators.forEach((item) => {
        arrayIndicatorId.push(item.id);
      });
      jsonIndicators = JSON.stringify(arrayIndicatorId);

      this.processWordFile(jsonIndicators);
    }

    // Rango de fechas
    if (this.selectedFilter === 2) {
      if (this.rangeDates.length > 0) {
        this.loadingWord = true;
        this.rangeDates = [
          new Date(this.rangeDates[0]),
          new Date(this.rangeDates[1]),
        ];
        await this.filterIndicatorsByDateRange();

        let arrayIndicatorId: (number | undefined)[] = [],
          jsonIndicators: string;
        this.filteredIndicatorsByDates.forEach((item) => {
          arrayIndicatorId.push(item.id);
        });
        jsonIndicators = JSON.stringify(arrayIndicatorId);

        this.processWordFile(jsonIndicators);
      } else {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Debe seleccionar un rango de fechas',
        });
      }
    }

    // ESRS
    if (this.selectedFilter === 3) {
      this.loadingWord = true;
      const esrsIndicators = this.filteredIndicators.filter((item) =>
        item['standards'].some((subitem) => subitem.standard_id === 1)
      );

      let arrayIndicatorId: (number | undefined)[] = [],
        jsonIndicators: string;
      esrsIndicators.forEach((item) => {
        arrayIndicatorId.push(item.id);
      });
      jsonIndicators = JSON.stringify(arrayIndicatorId);

      this.processWordFile(jsonIndicators);
    }

    // GRI
    if (this.selectedFilter === 4) {
      this.loadingWord = true;
      const griIndicators = this.filteredIndicators.filter((item) =>
        item['standards'].some((subitem) => subitem.standard_id === 2)
      );

      let arrayIndicatorId: (number | undefined)[] = [],
        jsonIndicators: string;
      griIndicators.forEach((item) => {
        arrayIndicatorId.push(item.id);
      });
      jsonIndicators = JSON.stringify(arrayIndicatorId);

      this.processWordFile(jsonIndicators);
    }
  }

  /**
   * Función auxiliar para procesado y descarga de archivo Word
   * @param jsonIndicators
   */
  processWordFile(jsonIndicators) {
    if (JSON.parse(jsonIndicators).length > 0) {
      this.serverService
        .sendData(
          `/api/getWordMultiIndicator/${jsonIndicators}`,
          jsonIndicators
        )
        .subscribe({
          next: (response) => {
            if (response) {
              this.loadingWord = false;
              this.dialogFilter = false;
              this.selectedFilter = null;
              this.rangeDates = [];
              window.open(response[0], '_blank');
              return this.messageService.add({
                severity: 'success',
                summary: 'Descarga exitosa',
                detail: 'El archivo Word ha sido generado y descargado',
              });
            }
          },
          error: (err) => {
            this.loadingWord = false;
            console.error('Error al descargar el archivo Word:', err);
          },
        });
    } else {
      this.loadingWord = false;
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'No se han encontrados indicadores para este filtro',
      });
    }
  }

  /**
   * Filtrado de indicadores por fecha de evidencias
   */
  async filterIndicatorsByDateRange() {
    const [startDate, endDate] = this.rangeDates;

    // Comprobamos si las fechas son válidas
    if (!startDate || !endDate) {
      return;
    }

    this.filteredIndicatorsByDates = this.filteredIndicators
      .map((indicator) => {
        // Comprobamos si el indicador tiene acciones
        if (!indicator.actions || indicator.actions.length === 0) {
          return null; // Si no tiene acciones, lo excluimos
        }

        const filteredActions = indicator.actions
          .map((action) => {
            // Comprobamos si la acción tiene evidencias
            if (!action.evidences || action.evidences.length === 0) {
              return null; // Si no tiene evidencias, excluimos la acción
            }

            const filteredEvidences = action.evidences.filter((evidence) => {
              const evidenceDate = new Date(evidence.date);
              return evidenceDate >= startDate && evidenceDate <= endDate;
            });

            // Si la acción tiene evidencias dentro del rango de fechas, devolvemos la acción con las evidencias filtradas
            if (filteredEvidences.length > 0) {
              return { ...action, evidences: filteredEvidences };
            }
            return null; // Si no tiene evidencias en el rango, devolvemos null
          })
          .filter((action) => action !== null); // Filtramos las acciones sin evidencias en el rango

        // Si el indicador tiene acciones con evidencias filtradas, lo devolvemos
        if (filteredActions.length > 0) {
          return { ...indicator, actions: filteredActions };
        }
        return null; // Si no tiene acciones en el rango, devolvemos null
      })
      .filter((indicator) => indicator !== null); // Filtramos los indicadores que no tienen acciones en el rango
  }

  /**
   * Check de indicador con un estándar
   */
  hasStandard(indicator, standardId: number): boolean {
    return indicator.standards.some(
      (standard) => standard.standard_id === standardId
    );
  }

  /**
   * Check de indicador con ambos estándares
   * @param indicator
   * @param standardId1
   * @param standardId2
   * @returns
   */
  hasBothStandards(
    indicator,
    standardId1: number,
    standardId2: number
  ): boolean {
    return (
      this.hasStandard(indicator, standardId1) &&
      this.hasStandard(indicator, standardId2)
    );
  }

  showGraphic(type) {
    // Gráfico de barras primera fila
    if (type == 'bar') {
      // Crear datasets dinámicamente
      interface Dataset {
        label: string;
        data: number[];
        backgroundColor: string;
        borderColor: string;
        borderWidth: number;
      }
      const datasets: Dataset[] = [];
      const propiedades = [
        'Validados',
        'No Validados',
        'No Aplica',
        'Sin tipificar',
      ];
      const backgroundColors = [
        'rgb(60, 179, 113)',
        'rgb(255, 99, 132)',
        'rgb(35, 124, 188)',
        'rgb(95, 24, 38)',
      ]; //verde,rojo,azul
      const propiedadesCont = this.dataGraphicBar.value[0].length;

      for (let i = 0; i < propiedadesCont; i++) {
        const dataset = {
          label: propiedades[i],
          data: this.dataGraphicBar.value.map((data) => data[i]),
          backgroundColor: backgroundColors[i],
          borderColor: backgroundColors[i],
          borderWidth: 1,
        };
        datasets.push(dataset);
      }

      this.chartBarData = {
        labels: this.dataGraphicBar.label,
        datasets: datasets,
      };

      this.chartBarOptions = {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'Indicadores por sección',
          },
        },
        scales: {
          x: {
            stacked: true, // Apilar las barras en el eje X
          },
          y: {
            stacked: true, // Apilar las barras en el eje Y
            beginAtZero: true, // Asegurarse de que el eje Y comienza en 0
          },
        },
      };
    }
  }

  /**
   * Función para descargar indicadores padre y sus descendientes
   */
  downloadGrandchildrenWords(items, level: number) {
    const itemsToDownload: any[] = [];

    // Según el nivel que estemos, obtenemos los id de distinta manera
    if (level === 1) {
      items['items'].forEach((subitem) => {
        subitem['items'].forEach((subsubitem) => {
          subsubitem['indicatorNames'].forEach((subsubsubitem) => {
            itemsToDownload.push(subsubsubitem['id']);
          });
        });
      });

      this.processWordFile(JSON.stringify(itemsToDownload));
    }

    if (level === 2) {
      items['items'].forEach((subitem) => {
        subitem['indicatorNames'].forEach((subsubsubitem) => {
          itemsToDownload.push(subsubsubitem['id']);
        });
      });

      this.processWordFile(JSON.stringify(itemsToDownload));
    }

    if (level === 3) {
      items['indicatorNames'].forEach((subsubsubitem) => {
        itemsToDownload.push(subsubsubitem['id']);
      });

      this.processWordFile(JSON.stringify(itemsToDownload));
    }
  }

  /**
   * Generador de colores para celdas de porcentaje de tabla de niveles de indicadores
   * @param percentage
   * @returns
   */
  getColorForPercentage(percentage: number): {
    backgroundColor: string;
    textColor: string;
  } {
    const normalizedPercentage = percentage / 100;

    // Calcular el color de fondo (rojo para 0%, verde para 100%)
    const r = Math.round(255 * (1 - normalizedPercentage)); // Rojo disminuye
    const g = Math.round(255 * normalizedPercentage); // Verde aumenta
    const b = 0; // Azul se mantiene en 0 para un degradado entre rojo y verde

    const backgroundColor = `rgb(${r}, ${g}, ${b})`;

    // Calcular la luminosidad del color de fondo
    const luminosity = 0.299 * r + 0.587 * g + 0.114 * b;

    // Si la luminosidad es baja (menos de 128), el texto debe ser blanco, si no, negro
    const textColor = luminosity < 128 ? 'white' : 'black';

    return { backgroundColor, textColor };
  }

  /**
   * Descarga de documento XBRL con indicadores del nivel seleccionado
   * @param items
   */
  downloadGrandchildrenXBRL(items) {
    const itemsToDownload: any[] = [];
    const extractedLabel = items.label.split(' - ')[0];

    items['items'].forEach((subitem) => {
      subitem['items'].forEach((subsubitem) => {
        subsubitem['indicatorNames'].forEach((subsubsubitem) => {
          itemsToDownload.push(subsubsubitem['id']);
        });
      });
    });

    // Procesar descarga de XBRL
    const jsonIndicators = JSON.stringify(itemsToDownload);
    const level = items.id;

    // Procesar datos al servidor
    this.serverService.getData(`/api/xbrl/${level}/create`).subscribe({
      next: (response) => {
        window.open(response[0], '_blank');
      },
      error: (err) => {
        console.error('Error al general documento XBRL', err);
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail:
            'Ocurrió un error al descargar el documento XBRL del capítulo ' +
            extractedLabel +
            ', inténtelo de nuevo',
        });
      },
    });
  }
}
